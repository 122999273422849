.slider-section .slider-section__content {
  max-width: 768px;
  margin: 0 auto;
  padding: 2.8rem 0.8rem;
}
.slider-section .slider-section__content h1 {
  color: var(--white-color);
  text-align: center;
  text-shadow: 2px 2px 2px var(--light-black);
}
.slider-section .slider-section__content p {
  color: var(--white-color);
  text-align: center;
  text-shadow: 2px 2px 2px var(--light-black);
}
.slider-section .slider-section__services {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 2.8rem;
  column-gap: 2.8rem;
  padding: 2.8rem 0.8rem;
}
.slider-section .slider-section__services .slider-section__services--box {
  background-color: rgba(13, 48, 79, 0.9);
  padding: 1.8rem 1.8rem 2.8rem 1.8rem;
  border-radius: 10px;
  text-align: center;
}
.slider-section .slider-section__services .slider-section__services--box h2 {
  color: var(--white-color);
  margin-bottom: 1.8rem;
}
.slider-section .slider-section__services .slider-section__services--box a {
  text-decoration: none;
}

@media (min-width: 768px) {
  .slider-section .slider-section__services {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1400px) {
  .slider-section .slider-section__services {
    grid-template-columns: repeat(4, 1fr);
  }
}